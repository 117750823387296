/**
 * ajax底层工具函数封装
 */
import axios from "axios";

import { versionfn } from "../utils/version";
import { displaynotify } from "../utils/displaynote";
import { report } from "@/aips/global";

//  import { Message } from 'element-ui';
import { Message } from "element-ui";
var version = versionfn.version;
let errorType = null;
let errormessage = null;
console.log(process.env.VUE_APP_GC_URL);
const axiosInstance1 = axios.create({
  baseURL: "http://dev.graphchain.top:1001/generic-canvas",
  timeout: 20000, // 请求超时时间
});
axios.defaults.baseURL = process.env.VUE_APP_GC_URL; // 发送ajax的服务器地址

const request = axios.create({
  baseURL: process.env.VUE_APP_GC_URL,
  timeout: 20000, // 请求超时时间
});
// axios.defaults.baseURL = "http://dev.graphchain.top:1001/chain-api"; // 发送ajax的服务器地址
// axios.defaults.baseURL = "http://192.168.31.130:8081/chain-api"; // 发送ajax的服务器地址
// 发送ajax的服务器地址
// export const weixinfn = {
//   // weixinURL:
//   //   "https://6c22e15b.r2.cpolar.top/wx/bind/wx1f23d9850941884a/qrCode?gcToken=",
//     weixinURL:
//     "http://wechat.graphchain.online/wx/bind/wx2d52763b9e9557a5/qrCode?gcToken=",
// };
// axios.defaults.timeout = 20000; // 请求超时时间，如果请求时间超过10秒，就报错。
// 允许携带cookie
request.interceptors.request.use(
  (config) => {
    // 把本地存储的token取出来（ 登录成功的时候存入的 ）

    let token = window.localStorage.getItem("t_k");
    //  let token = 'db9a1517-13d5-4e67-9e22-54eef19f00a4'
    // let AccessKey = window.localStorage.getItem('AccessKey')
    config.headers["Cache-Control"] = "no-cache";
    config.headers["Pragma"] = "no-cache";
    config.headers["If-Modified-Since"] = "0"; // 确保每次都获取最新数据
    // 请求头携带token字段（身份证） 【所有的请求 都是基于走这里 都会自动带上】
    if (token) {
      config.headers.authorization = token;
    }
    //  if (AccessKey) {
    //     config.headers.AccessKey  = AccessKey;
    // }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 响应拦截
request.interceptors.response.use(
  (response) => {
    // 判断 如果有code 和 msg 字段
    // let { statu} = response.data;
    // //console.log(response.data.code);
    // //console.log(displaynotify.isShow);

    if (response.data.code) {
      if (displaynotify.isShow) {
        if (response.data.code === 200) {
          // //console.log(11);
          // Message({
          //     type: "success",
          // });
        } else if (response.data.code === 300) {
          Message.warning({ message: response.data.message, duration: 1000 });
        } else if (response.data.code === 500) {
          // 在响应码为 500 时获取请求路径和参数
          const { config } = response;

          const { method, url, params, data } = config;
          // console.log("请求方法:", method);
          // console.log("请求路径:", url);
          // console.log("请求参数:", params || data);

          // 处理网络异常的逻辑
          Message.warning({
            message: "网络异常，请检查网络，或者稍后重试",
            duration: 1000,
          });
          errorType = "SYSTEM_EXCEPTION";
          errormessage = response.data.message;
          if (url !== "V2/clientReport") {
            reportError(config); // 这里调用函数发送请求报告错误
          }
          // this.$message.error('错了哦，这是一条错误消息');
        }
      }

      return response.data;
    } else {
      return response;
    }
  },
  (err) => {
    console.log(err);
    // 请求超时错误处理
    if (err.code === "ECONNABORTED" && err.message.indexOf("timeout") !== -1) {
      console.log("请求超时");
      // conditionInfo().then((res) => {
      //   console.log("我是请求超时的接口");
      //   console.log(res);
      // });
      const request = err.config; // 获取请求对象
      const { method, url, params, data } = request; // 获取请求方法、路径、参数等信息
      // console.log("请求方法:", method);
      // console.log("请求路径:", url);
      // console.log("请求参数:", params || data);
      Message.error({
        message: "请求超时，请检查网络或稍后重试",
        duration: 1000,
      });
      errorType = "TIMEOUT";
      errormessage = null;
      // if (url !== "V2/clientReport") {
      //   reportError(request); // 这里调用函数发送请求报告错误
      // }

      // 错误处理  如果错误码是401 就跳清除本地 跳转到登录页面
    } else if (err.response.status == 401) {
      window.localStorage.clear();
      this.$router.push("/login");
    } else if (err.response.status == 403) {
      if (displaynotify.isShow) {
        // Message.error({ message: "权限不足" });
      }
    }
    // else if (err.response.status == 403) {
    //   if (displaynotify.isShow) {
    //     Message.error({ message: "权限不足" });
    //   }
    // }

    return Promise.reject(err);
  }
);

axiosInstance1.interceptors.response.use(
  (response) => {
    console.log("响应拦截器 2：转换响应数据");

    // 假设我们要转换响应数据的格式
    // response.data = { transformed: true, originalData: response.data };
    if (response.data.code) {
      if (response.data.code === 200) {
        // //console.log(11);
        // Message({
        //     type: "success",
        // });
      } else if (response.data.code === 300) {
        Message.warning({ message: response.data.message, duration: 1000 });
      } else if (response.data.code === 500) {
        // 在响应码为 500 时获取请求路径和参数
        const { config } = response;

        const { method, url, params, data } = config;
        // console.log("请求方法:", method);
        // console.log("请求路径:", url);
        // console.log("请求参数:", params || data);

        // 处理网络异常的逻辑
        Message.warning({
          message: "网络异常，请检查网络，或者稍后重试",
          duration: 1000,
        });
        errorType = "SYSTEM_EXCEPTION";
        errormessage = response.data.message;
        if (url !== "V2/clientReport") {
          reportError(config); // 这里调用函数发送请求报告错误
        }
        // this.$message.error('错了哦，这是一条错误消息');
      }
      return response;
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 发送请求报告错误的函数
function reportError(request) {
  console.log("在这里发送请求向后端报告错误，可以使用axios再次发送请求");
  console.log(request);
  // 在这里发送请求向后端报告错误，可以使用axios再次发送请求
  request
    .post(`${version}/clientReport`, {
      exceptionType: errorType,
      path: request.url,
      parameter: request.data,
      message: errormessage,
    })
    .then((response) => {
      console.log("错误已报告：", response);
    })
    .catch((error) => {
      console.error("报告错误时出错：", error);
    });
}
// 暴露出去
export { axiosInstance1, request };
